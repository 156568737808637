import { values } from 'lodash';

import {
  DIVIDER_TYPE,
  GALLERY_TYPE,
  GIPHY_TYPE,
  HASHTAG_TYPE,
  HTML_TYPE,
  IMAGE_TYPE,
  LINE_SPACING_TYPE,
  LINK_TYPE,
  TEXT_COLOR_TYPE,
  VIDEO_TYPE,
} from './rich-content';

import isNextActive from '../../../packages/is-next-active';

import { BLOG_RCE_STATICS, BLOG_RCE_NEXT_STATICS } from '../constants/statics';

export const useNextRCE = isNextActive;
export const MAX_PINTEREST_IMAGE_SIZE = 5000;

//HACK: should be used from OOI
export const HTML_IFRAME_SRC = `https://${
  useNextRCE ? BLOG_RCE_NEXT_STATICS : BLOG_RCE_STATICS
}/html-plugin-embed.html`;

export const getPluginConfig = ({ enableLinks = true, isGiphyPluginEnabled } = {}) => {
  const PLUGINS = {
    IMAGE: IMAGE_TYPE,
    GALLERY: GALLERY_TYPE,
    VIDEO: VIDEO_TYPE,
    HASHTAG: HASHTAG_TYPE,
    DIVIDER: DIVIDER_TYPE,
    HTML: HTML_TYPE,
    TEXT_COLOR: TEXT_COLOR_TYPE,
    LINE_SPACING: LINE_SPACING_TYPE,
  };

  if (enableLinks) {
    PLUGINS.LINK = LINK_TYPE;
  }

  if (isGiphyPluginEnabled && GIPHY_TYPE) {
    PLUGINS.GIPHY = GIPHY_TYPE;
  }

  const DEFAULT_PLUGINS = values(PLUGINS);

  return { DEFAULT_PLUGINS, PLUGINS };
};
